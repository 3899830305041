import React from 'react'
import Axios from 'axios'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'

import Exclamation from '../../../assets/images/components/DSESignUpForm/exclamation.svg'

class DSESignUpForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			mailAddress: '',
			specialty: '',
			role: '',
			thankYou: false,
			validated: false,
			count: 0,
			submitClicked: false,
			hovered: false,
			error_msg_default: 'There was a problem with your submission. Please review the fields below.',
			error_msg: 'There was a problem with your submission. Please review the fields below.',
			emailValid: true,
			phoneValid: false
		}
	}

	postSignUpData(info) {
		Axios.post('/backend/submit.php', info).then(res => {
			if(res.data.status==='error'){
				this.setState({
					error_msg: 'There was a problem with your submission. Please try again later.'
					})
			}else{
				this.setState({
					thankYou: true,
				})
			}
		})
	}

	checkPhone(phoneNumber) {
		var cleaned = phoneNumber.replace(/\D/g, '');
		var regexUS = /^1?(\d{3})(\d{3})(\d{4})$/;
		if (regexUS.test(cleaned)) {
			return true;
		}
		return false;
	}

	checkEmail(email) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	}

	handleInputChange = event => {
		let newState = {}

		let lastVal = event.target.value.slice(-1)

		const letters = new RegExp(/^[a-zA-Z]/.test(lastVal)).toString()
		let lettersBool = letters === '/false/' ? false : true

		const nums = new RegExp(/\d+/g.test(lastVal)).toString()
		let numsBool = nums === '/false/' ? false : true

		if (event.target.name === 'firstName') {
			if ((!lettersBool || event.target.value.length > 50) && event.target.value.length !== 0) {
				event.preventDefault()
				event.stopPropagation()
			} else {
				newState[event.target.name] = event.target.value
				this.setState(newState)
			}
		} else if (event.target.name === 'lastName') {
			if ((!lettersBool || event.target.value.length > 100) && event.target.value.length !== 0) {
				event.preventDefault()
				event.stopPropagation()
			} else {
				newState[event.target.name] = event.target.value
				this.setState(newState)
			}
		} else if (event.target.name === 'email') {
			if (event.target.value.length > 320) {
				event.preventDefault()
				event.stopPropagation()
			} else {
				if(this.checkEmail(event.target.value)){
					this.setState({
						emailValid: true
					})
				}else{
					this.setState({
						emailValid: false
					})
				}
				newState[event.target.name] = event.target.value
				this.setState(newState)
			}
		} else if (event.target.name === 'phone') {
			if (event.target.value.length > 15) {
				event.preventDefault()
				event.stopPropagation()
			} else {
				if(this.checkPhone(event.target.value)){
					this.setState({
						phoneValid: true
					})
				}else{
					this.setState({
						phoneValid: false
					})
				}
				newState[event.target.name] = event.target.value
				this.setState(newState)
			}
		} else if (event.target.name === 'zipCode') {
			if ((!numsBool || event.target.value.length > 10) && event.target.value.length !== 0) {
				event.preventDefault()
				event.stopPropagation()
			} else {
				newState[event.target.name] = event.target.value
				this.setState(newState)
			}
		} else if (event.target.name === 'HowHasNPCimpactedYou') {
			newState[event.target.name] = event.target.value
			this.setState(newState)
			this.setState({
				HowHasNPCimpactedYou: event.target.value,
			})
		} else if (event.target.name === 'npcStage') {
			newState[event.target.name] = event.target.value
			this.setState(newState)
			this.setState({
				npcStageValid: true,
			})
		} else if (event.target.name === 'acceptValid') {
			this.setState({
				acceptValid: !this.state.acceptValid,
			})
		} else {
			newState[event.target.name] = event.target.value
			this.setState(newState)
		}

	}

	handleSubmit = event => {
		const form = event.currentTarget
		if (!this.state.HowHasNPCimpactedYou) {
			this.setState({
				HowHasNPCimpactedYou: false,
			})
		}

		if (!this.state.npcStage) {
			this.setState({
				npcStageValid: false,
			})
		}

		if (!this.state.acceptValid) {
			this.setState({
				acceptValid: false,
			})
		}
		if (!this.state.emailValid) {
			this.setState({
				submitClicked: true,
				validForm: false,
			})
			event.preventDefault()
			event.stopPropagation()
			return;
		}

		if (!this.state.phoneValid) {
			this.setState({
				submitClicked: true,
				validForm: false,
				error_msg: 'Phone must be a US or Puerto Rican number'
			})
			event.preventDefault()
			event.stopPropagation()
			return;
		} else{
			this.setState({
				error_msg: this.state.error_msg_default
			})
		}


		if (form.checkValidity() === false) {
			this.setState({
				submitClicked: true,
				validForm: false
			})
			event.preventDefault()
			event.stopPropagation()
		}

		if (form.checkValidity() === true) {
			event.preventDefault()
		}

		this.setState({
			validated: true,
		}, () => {
			if (form.checkValidity() === true) {
				let postData = {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					phone: this.state.phone,
					mailAddress: this.state.mailAddress,
					HowHasNPCimpactedYou: this.state.HowHasNPCimpactedYou,
					npcStage: this.state.npcStage,
					accept: this.state.acceptValid,
				}
				this.postSignUpData(postData)
			}
		})
	}
	render() {
		const {
			validated,
			firstName,
			lastName,
			email,
			emailValid,
			submitClicked,
			HowHasNPCimpactedYou,
			acceptValid,
			validForm,
			thankYou,
			npcStageValid,
			error_msg,
		} = this.state
		const {signUpForm} = this.props

		return (<section>
			<Container className='dse-sign-up'>
				<Row>
					<Col lg={{span: 10, offset: 1}}>
						{!thankYou ? (<div
							className={signUpForm ? '' : 'd-none'}
						>
							<Row>
								<Col
									className='px-0'
									sm={{span: 10}}

								>
									<h1 className='dse-sign-up__title'>
										Join the npc <br className='d-block d-md-none'/>facts Community
									</h1>
									<div className='dse-sign-up__subtitle dse-highlight-text'>
										The NPC Facts Community <br className='d-block d-md-none'/>
										is dedicated to providing <br className='d-block d-md-none'/>
										you with educational tools <br className='d-block d-md-none'/>
										and resources to help support <br className='d-block d-md-none'/>
										you on your NPC journey.
									</div>
									<p className='dse-sign-up__subtitle'>
										Learning about you helps us give you information that’s right for you. <br className='d-block d-lg-none'/>Complete the form below so we can send you the information you need.
									</p>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='form-container'>

										<Form
											className='dse-form_elements'
											noValidate
											validated={validated}
											onSubmit={this.handleSubmit}
										>
											<Row>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}
												>
													<div className={(validForm === false ? `d-flex` : `d-none`) + ` invalid-feedback mb-5`}>
														<img
															src={Exclamation}
															className='exclamation-icon'
															alt=''
															loading='lazy'
														/><div className='ml-3 d-flex align-items-center'>{error_msg}</div>
													</div>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}
												>
													<Form.Group
														controlId='validationCustom01'
														className={submitClicked === true && firstName === '' ? 'invalid-group' : ''}
													>
														<Form.Label column>*First name</Form.Label>
														<Form.Control
															type='text'
															name='firstName'
															value={firstName}
															required
															onChange={this.handleInputChange}
														/>
														<Form.Control.Feedback type='invalid'>
															Enter your first name.
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}

												>
													<Form.Group
														controlId='validationCustom02'
														className={submitClicked === true && lastName === '' ? 'invalid-group' : ''}
													>
														<Form.Label column>*Last name</Form.Label>
														<Form.Control
															type='text'
															name='lastName'
															value={lastName}
															required
															onChange={this.handleInputChange}
														/>
														<Form.Control.Feedback type='invalid'>
															Enter your last name.
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}

												>
													<Form.Group
														controlId='formHorizontalEmail'
														className={(submitClicked === true && email === '' ) || !(emailValid) ? 'invalid-group' : ''}
													>
														<Form.Label column>*Email address {emailValid}</Form.Label>
														<Form.Control
															type='email'
															name='email'
															value={email}
															required
															onChange={this.handleInputChange}
														/>
														<Form.Control.Feedback type='invalid'>
															Enter your email address.
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}

												>
													<Form.Group
														controlId='formHorizontalPhone'
														className={(submitClicked === true && this.state.phone === '') || (submitClicked === true && !this.state.phoneValid) ? 'invalid-group' : ''}
													>
														<Form.Label column>*Phone number</Form.Label>
														<Form.Control
															type='text'
															name='phone'
															value={this.state.phone}
															required
															onChange={this.handleInputChange}
														/>
														{ this.state.phone === '' &&
														<Form.Control.Feedback type='invalid'>
															Enter your phone number.
														</Form.Control.Feedback>
														}
														{ (this.state.phone && this.state.phone !== '') &&
														<div className='invalid-group-error'>Phone must be a US or Puerto Rican number phone</div>
														}
													</Form.Group>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}

												>
													<Form.Group
														controlId='validationCustom02'
													>
														<Form.Label column>Mailing address</Form.Label>
														<Form.Control
															type='text'
															name='mailAddress'
															value={this.mailAddress}
															onChange={this.handleInputChange}
														/>
													</Form.Group>
													<p className='required mt-3'>*Required field.</p>
												</Col>
											</Row>

											<Row>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}

												>
													<Form.Group controlId=''>
														<Col
															className={(HowHasNPCimpactedYou === false ? `is_invalid` : ``) + ` form-checkbox-col mt-5`}>
															<div
																className={(HowHasNPCimpactedYou === false ? `d-block` : `d-none`) + ` invalid-feedback mb-5`}>
																<img
																	src={Exclamation}
																	className='exclamation-icon'
																	alt=''
																	loading='lazy'
																/>Please select one to continue.
															</div>
															<h2 className='control-title'>
															WHICH OF THESE BEST DESCRIBES YOU?
															</h2>
															<p className='description'>Check the option that applies.</p>
															<div className='options__list'>
																<Form.Check
																	required
																	validated={validated}
																	type='radio'
																	id={`HowHasNPCimpactedYou`}
																	feedback="You must agree before submitting."
																	feedbackType="invalid"
																>
																	<Form.Check.Input
																		type='radio'
																		required
																		name={`HowHasNPCimpactedYou`}
																		value={`I have been diagnosed with NPC`}
																		onChange={this.handleInputChange}
																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																			I have been diagnosed with NPC
																		</p>
																	</Form.Check.Label>
																</Form.Check>
																<Form.Check
																	required
																	type='radio'
																	id={`A loved one has been diagnosed with NPC`}>
																	<Form.Check.Input
																		type='radio'
																		required
																		name={`HowHasNPCimpactedYou`}
																		value={`A loved one has been diagnosed with NPC`}
																		onChange={this.handleInputChange}
																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																		I care for someone who has been diagnosed with NPC
																		</p>
																	</Form.Check.Label>
																</Form.Check>
																<Form.Control.Feedback type="invalid">
																	Please provide a valid city.
																</Form.Control.Feedback>
															</div>
														</Col>
													</Form.Group>
												</Col>
												<Col
													className='dse-form_elements__group'
													sm={{span: 10}}
												>
													<Form.Group controlId=''>
														<Col className={(npcStageValid === false ? `is_invalid` : ``) + ` form-checkbox-col mt-5`}>
															<div
																className={(npcStageValid === false ? `d-block` : `d-none`) + ` invalid-feedback mb-5`}>
																<img
																	src={Exclamation}
																	className='exclamation-icon'
																	alt=''
																	loading='lazy'
																/>Please select one to continue.
															</div>


															<h2 className='control-title'>
																Which of these best describes the NPC stage of you or your loved one?
															</h2>
															<p className='description'>Check the option that applies.</p>
															<div className='options__list'>
																<Form.Check
																	type='radio'
																	validated={validated}
																	id={`NPC-STAGE`}>
																	<Form.Check.Input
																		type='radio'
																		required
																		name={`npcStage`}
																		value={`Newly diagnosed NPC that has not spread or has spread to nearby areas (stage 0-III)`}
																		onChange={this.handleInputChange}

																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																			Newly diagnosed NPC that has not spread or has spread to nearby areas (stage 0-III)
																		</p>
																	</Form.Check.Label>
																</Form.Check>
																<Form.Check
																	type='radio'
																	id={`npcStage-2`}>
																	<Form.Check.Input
																		type='radio'
																		required
																		name={`npcStage`}
																		value={`Previously diagnosed NPC that has come back and spread to nearby areas (stage II or III)`}
																		onChange={this.handleInputChange}

																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																			Previously diagnosed NPC that has come back and spread to nearby areas (stage II or III)
																		</p>
																	</Form.Check.Label>
																</Form.Check>

																<Form.Check
																	type='radio'
																	id={`npcStage-3`}>
																	<Form.Check.Input
																		type='radio'
																		required
																		name={`npcStage`}
																		value={`Newly or previously diagnosed NPC that has spread to other parts of the body (stage IV)`}
																		onChange={this.handleInputChange}

																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																			Newly or previously diagnosed NPC that has spread to other parts of the body (stage IV)
																		</p>
																	</Form.Check.Label>
																</Form.Check>
															</div>
														</Col>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col
													md={{span: 10}}
													className='dse-sign-up__disclosure'>
													<h2 className='control-title'>Uses and Disclosure of Personal Information</h2>
													<p>I authorize Coherus BioSciences,
														Inc. and its contractors and
														business partners (“Coherus”) to
														use and/or disclose my personal
														information, including my personal
														health information, only for the
														following purposes:</p>
													<ul>
														<li>To enroll me in, and/or continue my participation in Coherus’ informational updates
															program;
														</li>
														<li>To provide me with informational and marketing materials relating to Coherus products
															and services, and/or a condition or treatment;
														</li>
														<li>To improve, develop, and evaluate products, services, materials, and programs related to
															a condition or treatment.
														</li>
														<li>
															I understand that I may be contacted by mail, email, and/or telephone for any of these
															purposes. I further understand that the Coherus News Updates and additional information
															and marketing communications are optional and free services. I do not have to sign this
															authorization and this authorization in no way affects my right to obtain any medications.
															To obtain a copy of this authorization or to cancel at any time I can contact Coherus by
															calling <a href="tel:1-888-728-0419">1-888-728-0419</a>. The Coherus Privacy Policy can
															be found at <a className='word-break-all' target='_blank'
															href="https://www.coherus.com/privacy-policy/">https://www.coherus.com/privacy-policy/</a>.
															My consent is subject to all the terms and conditions stated in our
															privacy policy. You can make requests to know what personal information we have collected
															from you in the past 12 months or requests to delete your personal information via <a className='word-break-all' target='_blank' href="http://www.requesteasy.com/5ee3-7108">http://www.requesteasy.com/5ee3-7108</a> or <a href="tel:1-888-728-0419">1-888-728-0419</a>.
														</li>
													</ul>
												</Col>
												<Col
													md={{span: 10}}
													className='dse-sign-up__disclosure'>
													<h2 className='control-title'>LIMITATION OF LIABILITY </h2>
													<p>All the services, information,
														software, and other materials
														provided through the portal are
														provided on an “as-is” basis and
														without any express or implied
														warranties. While our database uses
														Blockchain security features, we do
														not guarantee the security of the
														database and connected portal(s)
														or the services or the prevention
														from loss of, alteration of, or
														improper access to your account
														information or data. To the maximum
														extent permitted by law, under no
														circumstances shall Coherus, its
														officers, directors, employees,
														subsidiaries, or affiliated companies
														be liable for any direct, indirect,
														incidental, special, consequential,
														or punitive damages, such as, but
														not limited to, loss of revenue, loss
														of anticipated profits, goodwill,
														diminution of value, business
														interruption costs, or any other
														intangible losses arising out of
														damage from any security breach
														or any other security intrusion of
														personal information.</p>
													<p>By checking the “I accept” box, I am
														stating electronically that I have
														read and understood Coherus’
														personal information terms (above
														in its full text), that I am at least 18
														years old and authorize Coherus
														BioSciences, Inc., its contractors,
														and its business partners to use
														and disclose my Personal
														Information for the purposes
														described above.</p>
													<p>In addition to the above consent, I
														understand that by checking this box
														and signing, I consent to Coherus
														calling and texting me at any of the
														methods of communication I have
														provided with promotional
														communications relating to Coherus
														product and services and/or my
														condition or treatment. Coherus may
														use automatic dialing machines or
														artificial or prerecorded messages
														to contact me and may leave a
														voicemail or SMS/text message
														(standard text messaging rates may
														apply). I understand that I am not to
														provide this consent as a condition
														of purchasing any goods or services.
														I have the ability to reply STOP to
														cancel messages at anytime.</p>
													<p>This is a security feature to enhance
														your protection.</p>
												</Col>
											</Row>
											<Row>
												<Col
													className='px-0'
													md={{span: 10}}
												>
													<div className={(acceptValid === false ? `d-block` : `d-none`) + ` invalid-feedback mb-5`}>
														<img
															src={Exclamation}
															className='exclamation-icon'
															alt=''
															loading='lazy'
														/>Please select the checkbox to continue.
													</div>
												</Col>
												<Col
													md={{span: 10}}
													className='dse-form_elements__group d-md-flex justify-content-center px-0'
												>
													<Form.Group controlId=''>

														<Col className={(acceptValid === false ? `is_invalid` : ``) + ` form-checkbox-col mt-5`}>

															<div className='options__list'>
																<Form.Check
																	type='radio'
																	id={`acceptValid`}>
																	<Form.Check.Input
																		type='checkbox'
																		required
																		name={`acceptValid`}
																		onChange={this.handleInputChange}
																	/>
																	<Form.Check.Label className='privacy-policy-container'>
																		<p>
																			I accept
																		</p>
																	</Form.Check.Label>
																</Form.Check>
															</div>
														</Col>
													</Form.Group>
												</Col>
												<Col
													md={{span: 10}}
												>
													<Form.Group className='form-submit-btn-row'>
														<div className='cta-btn-container form-submit-btn-container'>
															<Button
																type='submit'
																id='form-submit-btn'
																className='dse-sign-up__btn'
																title="Join the NPC Community"
															>
																Join
															</Button>
														</div>
													</Form.Group>
												</Col>
											</Row>
										</Form>
									</div>
								</Col>
							</Row>
						</div>) : (
							<div>
								<Row>
									<Col
										className='px-0'
									>
										<h1 className='dse-sign-up__title dse-sign-up__title--thank-you'>
											Thank you for joining our community
										</h1>
										<p className='dse-sign-up__subtitle dse-sign-up__subtitle--thank-you dse-highlight-text'>
											We look forward to <br className='d-block d-md-none'/>
											supporting you on your <br className='d-block d-md-none'/>
											NPC journey.
										</p>
										<p className='dse-sign-up__subtitle dse-sign-up__subtitle--thank-you'>
											Coherus BioSciences will <br className='d-block d-md-none'/> get back to you shortly.
										</p>
									</Col>
								</Row>
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</section>)
	}
}

export default DSESignUpForm;
